import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        meta: {
          topState: true,
        }

      }, 
      {
        path: '/userMustKnow',
        name: 'userMustKnow',
        component: () => import('@/views/pc/userMustKnow.vue'),
        meta: {
          title: '学员须知',
        }
      },{
        path: '/collegeInfo',
        name: 'collegeInfo',
        component: () => import('@/views/pc/collegeInfo.vue'),
        meta: {
          title: '学院介绍'
        }
      }, {
        path: '/newsList',
        name: 'newsList',
        component: () => import('@/views/pc/newsList.vue'),
        meta: {
          title: '新闻列表'
        }

      }, {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/pc/newsDetail.vue'),
        meta: {
          title: '新闻详情'
        }

      }, {
        path: '/noticeList',
        name: 'noticeList',
        component: () => import('@/views/pc/noticeList.vue'),
        meta: {
          title: '通知公告'
        }

      }, {
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: () => import('@/views/pc/noticeDetail.vue'),
        meta: {
          title: '通知公告-详情'
        }

      },{
        path: '/rcpyIndex',
        name: 'rcpyIndex',
        component: () => import('@/views/pc/rcpyIndex.vue'),
        meta: {
          title: '人才培育'
        }

      }, {
        path: '/specialList',
        name: 'specialList',
        component: () => import('@/views/pc/specialList.vue'),
        meta: {
          title: '专题聚焦'
        }
      },{
        path: '/specialDetail',
        name: 'specialDetail',
        component: () => import('@/views/pc/specialDetail.vue'),
        meta: {
          title: '专题聚焦-详情'
        }
      },{
        path: '/cxcgIndex',
        name: 'cxcgIndex',
        component: () => import('@/views/pc/cxcgIndex.vue'),
        meta: {
          title: '创新成果-首页',
          topState:true,
        }
      },{
        path: '/cxcgList',
        name: 'cxcgList',
        component: () => import('@/views/pc/cxcgList.vue'),
        meta: {
          title: '创新成果-列表'
        }
      },{
        path: '/cxcgDetail',
        name: 'cxcgDetail',
        component: () => import('@/views/pc/cxcgDetail.vue'),
        meta: {
          title: '创新成果-详情'
        }
      },{
        path: '/cxxmIndex',
        name: 'cxxmIndex',
        component: () => import('@/views/pc/cxxmIndex.vue'),
        meta: {
          title: '创新项目-首页',
          topState:true,
        }
      },{
        path: '/cxxmList',
        name: 'cxxmList',
        component: () => import('@/views/pc/cxxmList.vue'),
        meta: {
          title: '创新项目-列表'
        }
      },{
        path: '/cxxmDetail',
        name: 'cxxmDetail',
        component: () => import('@/views/pc/cxxmDetail.vue'),
        meta: {
          title: '创新项目-详情'
        }
      },
      // {
      //   path: '/ntggList',
      //   name: 'ntggList',
      //   component: () => import('@/views/pc/ntggList.vue'),
      //   meta: {
      //     title: '难题攻关-列表'
      //   }
      // },{
      //   path: '/ntggDetail',
      //   name: 'ntggDetail',
      //   component: () => import('@/views/pc/ntggDetail.vue'),
      //   meta: {
      //     title: '难题攻关-详情'
      //   }
      // },
      {
        path: '/knowledgeDetail',
        name: 'knowledgeDetail',
        component: () => import('@/views/pc/knowledgeDetail.vue'),
        meta: {
          title: '知识交流-详情'
        }
      },{
        path: '/login',
        name: 'login',
        component: () => import('@/views/pc/login.vue'),
        meta: {
          title: '登录',
          topState:true,
        }
      },{
        path: '/register',
        name: 'register',
        component: () => import('@/views/pc/register.vue'),
        meta: {
          title: '注册',
          topState:true,
        }
      },{
        path: '/teacherDetail',
        name: 'teacherDetail',
        component: () => import('@/views/pc/teacherDetail.vue'),
        meta: {
          title: '师资详情',
          topState:true,
        }
      },{
        path: '/courseDetail',
        name: 'courseDetail',
        component: () => import('@/views/pc/courseDetail.vue'),
        meta: {
          title: '课程详情',
          topState:true,
        }
      },{
        path: '/studentDetail',
        name: 'studentDetail',
        component: () => import('@/views/pc/studentDetail.vue'),
        meta: {
          title: '学员详情',
          topState:true,
        }
      },{
        path: '/enrollmentDetail',
        name: 'enrollmentDetail',
        component: () => import('@/views/pc/enrollmentDetail.vue'),
        meta: {
          title: '招生详情',
          topState:true,
        }
      },{
        path: '/scientificDetail',
        name: 'scientificDetail',
        component: () => import('@/views/pc/scientificDetail.vue'),
        meta: {
          title: '学术科研详情',
          topState:true,
        }
      },
      {
        path: '/userCenter',
        name: 'UserCenter',
        component: () => import('@/views/pc/userCenter.vue'),
        meta: {
          title: '用户中心',
          topState:true,
        }
      },
      {
        path: '/onLine',
        name: 'OnLine',
        component: () => import('@/views/pc/onLine.vue'),
        meta: {
          title: '线上直播课',
          topState:true,
        }
      },
      {
        path: 'onLineInfo',
        name: 'OnLineInfo',
        component: () => import('@/views/pc/onLineInfo.vue'),
        meta: {
          title: '线上直播课',
          topState:true,
        }
      },
      {
        path: 'onLineVideo',
        name: 'OnLineVideo',
        component: () => import('@/views/pc/onLineVideo.vue'),
        meta: {
          title: '线上直播课',
          topState:true,
        }
      },
      {
        path: '/site',
        name: 'Site',
        component: () => import('@/views/pc/site.vue'),
        meta: { title: '学院建设点'}
      },
      {
        path: '/dggj/index',
        name: 'DggjIndex',
        component: () => import('@/views/pc/dggj/index.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/teacherList',
        name: 'TeacherList',
        component: () => import('@/views/pc/dggj/teacherList.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/teacherInfo',
        name: 'TeacherInfo',
        component: () => import('@/views/pc/dggj/teacherInfo.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseList',
        name: 'CourseList',
        component: () => import('@/views/pc/dggj/courseList.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseInfo',
        name: 'CourseInfo',
        component: () => import('@/views/pc/dggj/courseInfo.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseVideo',
        name: 'CourseVideo',
        component: () => import('@/views/pc/dggj/courseVideo.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/rankList',
        name: 'RankList',
        component: () => import('@/views/pc/dggj/rankList.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/studentList',
        name: 'StudentList',
        component: () => import('@/views/pc/dggj/studentList.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/studentInfo',
        name: 'StudentInfo',
        component: () => import('@/views/pc/dggj/studentInfo.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/clearinghouse',
        name: 'Clearinghouse',
        component: () => import('@/views/pc/dggj/clearinghouse.vue'),
        meta: { title: '大国工匠' }
      },
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/index'
  },
  {
    path: '/index',
    component: MHome,
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/m/index.vue'),
        meta: { title: '全国工匠学院'}
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/m/login.vue'),
        meta: { title: '登录'}
      },
      {
        path: '/forgetPass',
        name: 'ForgetPass',
        component: () => import('@/views/m/forgetPass.vue'),
        meta: { title: '忘记密码'}
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/m/register.vue'),
        meta: { title: '注册'}
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('@/views/m/news.vue'),
        meta: { title: '新闻公告'}
      },
      {
        path: '/infoPage',
        name: 'InfoPage',
        component: () => import('@/views/m/infoPage.vue'),
        meta: { title: ''}
      },
      {
        path: '/special',
        name: 'Special',
        component: () => import('@/views/m/special.vue'),
        meta: { title: '专题聚焦'}
      },
      {
        path: '/person',
        name: 'Person',
        component: () => import('@/views/m/person.vue'),
        meta: { title: '人才培育'}
      },
      {
        path: '/teacherInfo',
        name: 'TeacherInfo',
        component: () => import('@/views/m/teacherInfo.vue'),
        meta: { title: '人才培育'}
      },
      {
        path: '/courseInfo',
        name: 'CourseInfo',
        component: () => import('@/views/m/courseInfo.vue'),
        meta: { title: '人才培育'}
      },
      {
        path: '/userInfo',
        name: 'UserInfo',
        component: () => import('@/views/m/userInfo.vue'),
        meta: { title: '人才培育'}
      },
      {
        path: '/userMustKnow',
        name: 'userMustKnow',
        component: () => import('@/views/m/userMustKnow.vue'),
        meta: {
          title: '学员须知',
        }
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/m/about.vue'),
        meta: { title: '学院介绍'}
      },
      {
        path: '/chuangXin',
        name: 'ChuangXin',
        component: () => import('@/views/m/chuangXin.vue'),
        meta: { title: '工匠创新'}
      },
      {
        path: '/chuangXinInfo',
        name: 'chuangXinInfo',
        component: () => import('@/views/m/chuangXinInfo.vue'),
        meta: { title: '工匠创新'}
      },
      {
        path: '/site',
        name: 'Site',
        component: () => import('@/views/m/site.vue'),
        meta: { title: '学院建设点'}
      },
      {
        path: '/userCenter',
        name: 'UserCenter',
        component: () => import('@/views/m/userCenter.vue'),
        meta: { title: '个人中心'}
      },
      {
        path: '/userData',
        name: 'UserData',
        component: () => import('@/views/m/userData.vue'),
        meta: { title: '个人中心'}
      },
      {
        path: '/saiShang',
        name: 'SaiShang',
        component: () => import('@/views/m/saiShang.vue'),
        meta: { title: '个人中心'}
      },
      {
        path: '/saiShangXinDe',
        name: 'SaiShangXinDe',
        component: () => import('@/views/m/saiShangXinDe.vue'),
        meta: { title: '个人中心'}
      },
      {
        path: '/onLine',
        name: 'OnLine',
        component: () => import('@/views/m/onLine.vue'),
        meta: { title: '线上直播课' }
      },
      {
        path: '/onLineInfo',
        name: 'OnLineInfo',
        component: () => import('@/views/m/onLineInfo.vue'),
        meta: { title: '线上直播课' }
      },
      {
        path: '/updateSchool',
        name: 'UpdateSchool',
        component: () => import('@/views/m/updateSchool.vue'),
        meta: { title: '个人中心' }
      },
      {
        path: '/updatePassword',
        name: 'UpdatePassword',
        component: () => import('@/views/m/updatePassword.vue'),
        meta: { title: '个人中心' }
      },
      {
        path: '/authentication',
        name: 'authentication',
        component: () => import('@/views/m/authentication.vue'),
        meta: { title: '个人中心' }
      },
      {
        path: '/dggj/index',
        name: 'DggjIndex',
        component: () => import('@/views/m/dggj/index.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/rankList',
        name: 'DggjRankList',
        component: () => import('@/views/m/dggj/rankList.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseList',
        name: 'DggjCourseList',
        component: () => import('@/views/m/dggj/courseList.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseInfo',
        name: 'DggjCourseInfo',
        component: () => import('@/views/m/dggj/courseInfo.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseVideo',
        name: 'CourseVideo',
        component: () => import('@/views/m/dggj/courseVideo.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/courseMy',
        name: 'CourseMy',
        component: () => import('@/views/m/dggj/courseMy.vue'),
        meta: { title: '大国工匠' }
      },
      {
        path: '/dggj/clearinghouse',
        name: 'Clearinghouse',
        component: () => import('@/views/m/dggj/clearinghouse.vue'),
        meta: { title: '大国工匠' }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.afterEach(to => {
  if(!IsPC()){
    let title = to.meta.title;

    if(to.path == '/infoPage'){
      const type = to.query.type;

      if(type==7 || type==6){
        url = '人才培育';
      }else if(type == 5){
        title = '专题聚焦';
      }else{
        title = '新闻公告';
      }
    }

    document.title = title;
  }
})

export default router
