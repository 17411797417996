<template>
  <div class="header">
    <img class="logo" src="@/assets/m/logo.png">
    <div class="user-info a-single-ellipsis" v-if="userInfo">
      <img class="avatar" :src="userInfo.image || require('@/assets/pc/login/per_img.png')">
      {{ userInfo.name }}
    </div>
    <div class="login-btn" @click="$router.push('/login')" v-else>登录</div>
    <img class="menus" src="@/assets/m/menus-btn.png" @click="showMenu = !showMenu">
    <div class="menu-list-layer" v-if="showMenu">
      <ul class="menu-list">
        <li class="menu" :class="{'active': $route.name=='Index'}" @click="$router.push('/index',()=>{},()=>{}), showMenu=false">首页</li>
        <li class="menu" :class="{'active': $route.name=='About'}" @click="$router.push('/about',()=>{},()=>{}), showMenu=false">学院介绍</li>
        <li class="menu">建设指南</li>
        <li class="menu" :class="{'active': $route.name=='Site'}" @click="$router.push('/site',()=>{},()=>{}), showMenu=false">学院建设</li>
        <li class="menu">数据中心</li>
        <li class="menu" :class="{'active': $route.name=='News'}" @click="$router.push('/news',()=>{},()=>{}), showMenu=false">资讯中心</li>
        <li class="menu" :class="{'active': $route.name=='UserCenter'}" @click="$router.push('/userCenter',()=>{},()=>{}), showMenu=false">个人中心</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    ...mapState(['userInfo'])
  }
};
</script>

<style scoped lang="scss">
.header{
  width: 100%;
  height: 48px;
  padding: 0 14px;
  background: #FFF;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}
.user-info{
  max-width: 90px;
  font-size: 14px;
  color: #333333;
  margin-left: auto;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar{
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 4px;
}
.login-btn{
  font-weight: 500;
  font-size: 16px;
  color: #2973F0;
  margin-left: auto;
  margin-right: 40px;
}
.logo{
  height: 20px;
}
.menus{
  width: 20px;
}
.menu-list-layer{
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(15, 15, 15, 0.7);
  z-index: 999;
}
.menu-list{
  width: 179px;
  padding: 15px 30px;
  background: #FFFFFF;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 2px 4px 1px rgba(180,180,180,0.14);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  .menu{
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding-left: 43px;
    border-bottom: 1px solid #F7F7F7;
    font-size: 16px;
    color: #999;
    &:nth-child(1){
      background: url('@/assets/m/menu-1.png') no-repeat 12px center/16px;
    }
    &:nth-child(2){
      background: url('@/assets/m/menu-2.png') no-repeat 12px center/16px;
    }
    &:nth-child(3){
      background: url('@/assets/m/menu-3.png') no-repeat 12px center/16px;
    }
    &:nth-child(4){
      background: url('@/assets/m/menu-4.png') no-repeat 12px center/16px;
    }
    &:nth-child(5){
      background: url('@/assets/m/menu-5.png') no-repeat 12px center/16px;
    }
    &:nth-child(6){
      background: url('@/assets/m/menu-6.png') no-repeat 12px center/16px;
    }
    &:nth-child(7){
      background: url('@/assets/m/menu-7.png') no-repeat 12px center/16px;
    }
    &.active{
      color: #2973F0;
      &:nth-child(1){
        background: url('@/assets/m/menu-11.png') no-repeat 12px center/16px;
      }
      &:nth-child(2){
        background: url('@/assets/m/menu-22.png') no-repeat 12px center/16px;
      }
      &:nth-child(3){
        background: url('@/assets/m/menu-33.png') no-repeat 12px center/16px;
      }
      &:nth-child(4){
        background: url('@/assets/m/menu-44.png') no-repeat 12px center/16px;
      }
      &:nth-child(5){
        background: url('@/assets/m/menu-55.png') no-repeat 12px center/16px;
      }
      &:nth-child(6){
        background: url('@/assets/m/menu-66.png') no-repeat 12px center/16px;
      }
      &:nth-child(7){
        background: url('@/assets/m/menu-77.png') no-repeat 12px center/16px;
      }
    }
  }
}
</style>
